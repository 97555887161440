<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>Contribute</span>
        </portal>

        <form id="asset-form">
            <div>
                <h3>CONTRIBUTING TOKENS</h3>
                <p><strong>Get started by selecting your asset below</strong>.</p>

                <div class="form-group asset-form">
                    <label>Asset</label>
                    <div class="row">
                        <div class="col-md-6">
                            <div id="asset-container">
                            </div>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                    </div>
                    <small class="form-text text-muted"> Start typing the name of the token, or paste in its contract
                        address.
                    </small>
                    <div class="row">
                        <div class="col-md-6">
                            <ProgressBar style="display: none"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="asset-info" style="display: none;">
                <h3 class="asset-name"></h3>
                <p>
                    <a href="#" target="_blank" class="etherscan-link">View On Etherscan</a>
                </p>

                <div class="accepted-wrap">
                    <div class="data">
                        <span class="label">
                            Market Value
                            <Info>The average value of the token on exchanges over the past few days.</Info>
                        </span><span class="data-value market-value"></span>
                    </div>

                    <div class="data">
                        <span class="label">
                            Estimated Bonus
                            <Info>The estimated percentage bonus above the token's market value you'll receive.</Info>
                        </span><span class="data-value estimated-bonus"></span>
                    </div>

                    <div class="data">
                        <span class="label">
                            Contribution Cap
                            <Info>The maximum amount of this token that Blockwell will accept as a contribution.</Info>
                        </span><span class="data-value contrib-cap"></span>
                    </div>

                    <p>Fill the form below and we'll send you a quote that's guaranteed for 24 hours. You'll also
                        receive instructions on where to send the tokens.</p>

                    <div v-if="!email" class="form-group">
                        <label>Email Address</label>
                        <div class="row">
                            <div class="col-md-5">
                                <input type="email" class="form-control" name="email">
                            </div>
                        </div>
                        <div class="invalid-feedback"></div>
                        <small class="form-text text-muted">
                            Enter the email address you want to use.
                        </small>
                    </div>

                    <div class="form-group">
                        <label>Token Amount</label>
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" class="form-control" name="amount">
                            </div>
                        </div>
                        <div class="invalid-feedback"></div>
                        <small class="form-text text-muted">
                            Enter the amount of tokens you want to contribute. The quote is only guaranteed if you send
                            the same amount of tokens that you enter here, but you can send a different amount as well.
                        </small>
                    </div>
                    <div class="form-group">
                        <button id="submit" class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </div>
                <p class="asset-error error">

                </p>
            </div>
        </form>
        <ul>
            <li>Blockwell accepts contribution in any ERC20 with over $50k/day trade volume. Turn your shitcoins into
                hit coins.
            </li>
            <li>During our presale any accepted ERC20 has an algorithmically generated bonus above market rate depending
                on volatility, current Blockwell holdings, and other factors.
            </li>
            <li>A portion of your contribution will be promptly liquidated but we will retain a double digit percentage
                of tokens with volume that are new to us for a short period and evaluate ways they can easily be
                improved as part of the Blockwell ecosystem.
            </li>
        </ul>


        <h3 class="direct-contrib"><span class="logo"><img src="../assets/images/ethereum.png" height="24"
                                                           alt=""></span>
            Contributing Ether</h3>
        <p>You can contribute directly using Ether.</p>
        <div class="address-display">
            <Clipboard :address="ethAddress"/>
        </div>
        <p>
            Send ETH to the above address. Your contribution will be calculated based on the ETH price at the time you
            send it.
        </p>
        <h3 class="direct-contrib"><span class="logo"><img src="../assets/images/bitcoin.png" height="24" alt=""></span>
            Contributing Bitcoin</h3>
        <p>You can also contribute directly using Bitcoin.</p>
        <div class="address-display">
            <Clipboard :address="btcAddress"/>
        </div>
        <p>
            Send BTC to the above address. Your contribution will be calculated based on the BTC price at the time you
            send it.
        </p>
        <p>&nbsp;</p>
        <h2>KYC</h2>
        <p>
            <strong>Following your contribution, you will need to pass KYC</strong>. Please visit the
            <router-link to="/app/account/kyc">KYC Page</router-link>
            .
        </p>
        <p>
            If you don't submit or pass KYC and validate your wallet within 30 days your contribution will be refunded
            in the medium it was received minus network fees.
        </p>


        <div class="modal fade" id="submit-modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Submitted</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>You will receive an email shortly with the 24 hour guaranteed quote, and instructions for how
                            to proceed.</p>
                    </div>
                </div>
            </div>
        </div>
    </layout-heading>
</template>

<script>
    import Clipboard from "@/components/Clipboard";
    import Info from "@/components/Info";
    import ProgressBar from "@/components/ProgressBar";
    import axios from 'axios';
    import * as util from "@/assets/lib/util";
    import * as R from 'rambdax';
    import 'bootstrap';
    import Api from '@/lib/api/WalletApi';
    import * as filter from 'secure-filters';
    import accessibleAutocomplete from "accessible-autocomplete";
    import BigNumber from 'bignumber.js';
    import Cleave from 'cleave.js';
    import {getNetwork} from '@/assets/lib/networks';
    import {mapGetters, mapState} from "vuex";

    const api = new Api('legacy');

    export default {
        name: "Contrib",
        components: {Info, Clipboard, ProgressBar},
        computed: {
            ...mapGetters('user', ['api']),
            ...mapState('user', ['email'])
        },
        asyncComputed: {
            ethAddress() {
                return this.$config.get('ether_contrib_address');
            },
            btcAddress() {
                return this.$config.get('bitcoin_contrib_address');
            }
        },
        mounted() {
            accessibleAutocomplete({
                element: $('#asset-container')[0],
                id: 'asset',
                displayMenu: 'overlay',
                name: 'contract',
                placeholder: 'Tether,  zrx, dai, Huobi...',
                source: function (query, populateResults) {
                    api.tokenSearch(query).then(list => {
                        populateResults(list);
                    });
                },
                templates: {
                    inputValue: it => {
                        return it ? it.address : "";
                    },
                    suggestion: it => {
                        return filter.html(it.name);
                    }
                },
                onConfirm: value => {
                    if (value && util.addressRegex.test(value.address)) {
                        loadContract(value.address, value.name);
                    }
                }
            });

            let amountFormat = new Cleave('input[name=amount]', {
                numeral: true,
                numeralDecimalScale: 18,
                numeralPositiveOnly: true
            });

            $('#asset').on('input', function () {
                let value = $(this).val().trim();

                if (util.addressRegex.test(value)) {
                    $('.autocomplete__menu').hide();
                    loadContract(value);
                } else {
                    $('.autocomplete__menu').attr('style', '');
                }
            });

            $('#asset-form').submit(function (ev) {
                ev.preventDefault();
                $('.asset-error').empty();
                let $submit = $('#submit').attr('disabled', true);

                let data = util.getFormData(this);

                if (!data.contract && address) {
                    data.contract = address;
                }

                data.amount = amountFormat.getRawValue();

                api.submitContrib(data.contract, data.amount, data.email)
                    .then(res => {
                        $('input[name=amount]').val('');
                        $submit.attr('disabled', null);
                        $('#submit-modal').modal();
                    })
                    .catch(err => {
                        let message = R.pathOr(err.message, 'response.data.error.message', err);

                        $('.asset-error').text(util.errorText(message)).show();
                        $submit.attr('disabled', null);
                    });
            });

            const urlParams = new URLSearchParams(window.location.search);
            let contract = urlParams.get('contract');

            if (contract) {
                loadContract(contract);
            }
        }
    }

    let address;

    function loadContract(addr, searchName = null) {
        if (address === addr) {
            return;
        }

        address = addr;
        let name = searchName;

        $('.progress').show();

        updateUrl(addr);

        (async () => {
            let data = await api.contribData(addr);

            if (!name) {
                name = `${data.name} (${data.symbol})`;
            }

            $('.asset-name').text(name);
            $('.etherscan-link').attr('href', getNetwork(1).explorerToken(addr));

            $('.progress').hide();

            let bonusLow = Math.floor(data.bonus[0] * 100);
            let bonusHigh = Math.floor(data.bonus[1] * 100);

            if (data.accepted === true) {
                $('.market-value').text('$' + data.value.toFixed(4));
                $('.estimated-bonus').text(`${bonusLow} - ${bonusHigh}%`);
                $('.contrib-cap').text(new BigNumber(data.cap).toFormat(0) + " " + (data.symbol || ""));
                $('.accepted-wrap').show();
                $('.asset-error').hide();
            } else {
                $('.accepted-wrap').hide();
                $('.asset-error').text(data.accepted).show();
            }

            await util.slideDown($('.asset-info'));
        })()
            .catch(err => {
                $('.asset-form .invalid-feedback').text(util.errorText(err.message)).show();
            });
    }


    function updateUrl(address) {
        if (history.pushState) {
            const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
                + '?contract=' + address;
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

</script>

<style lang="scss" scoped>
    .data ::v-deep( .label ) {
        width: 160px;
    }
</style>
